import { AfterViewInit } from '@angular/core';
import { ConsoleLoggerService } from './ConsoleLoggerService';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { AuthService } from './pages/core/auth.service';
import { Observable, interval, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { BackendServerConf } from './pages/core/backend-server-conf';
import { JWTTokenService } from './pages/core/jwt.decoder';
import { Router } from '@angular/router';
import { UniteService } from './simlait/unite/unite.service';
import { Unite } from './simlait/unite/unite.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;

  constructor(public appSettings: AppSettings,
    public jwtService: JWTTokenService, private authService: AuthService,
    private ref: ChangeDetectorRef, private router: Router,
    private _consoleLogerService: ConsoleLoggerService) {
    this.settings = this.appSettings.settings;

    const token = this.authService.getToken();

    setInterval(function () {
      if (token != null) {
        const tokenExpiration = new Date(JSON.parse(atob(token.split('.')[1])).exp * 5000);
        const now = new Date();
        const timeDifference = tokenExpiration.getTime() - now.getTime();
        if (timeDifference <= 0) {
          this.authService.logout();
          return;
        }
      }
    }, 50000);
  }



  ngOnInit() {
    this._consoleLogerService.disableLogsInProd();

  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }


}