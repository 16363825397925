import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendServerConf } from 'src/app/pages/core/backend-server-conf';
import { ActivityNotification } from './activityNotifications.model';


@Injectable()
export class ActivityNotificationService {

    readonly url: string = `${BackendServerConf.baseUrlBackend}/api/activity_notifications`;

    constructor(public http: HttpClient) { }

    findAll(): Observable<ActivityNotification[]> {
        return this.http.get<ActivityNotification[]>(`${this.url}`).map(data => data);
    }


    create(body) {
        return this.http.post(`${this.url}`, body);
    }


    delete(id: string) {
        return this.http.delete(`${this.url}/${id}`);
    }

    findOne(id: string) {
        return this.http.get(`${this.url}/${id}`);
    }

    update(form: any) {
        return this.http.put(`${this.url}/${form.id}`, form);
    }


}
