import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { Menu } from '../menu.model';
import { MenuService } from '../menu.service';

declare let getComputedStyle: any;

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})


export class VerticalMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  parentMenu: Menu[];

  @ViewChild('colorAccent') colorAccent: ElementRef;

  public settings: Settings;
  constructor(public appSettings: AppSettings, public menuService: MenuService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.parentMenu = this.menuItems.filter((item: Menu) => item.parentId == this.menuParentId);

  }

  onClick(menuId) {
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
  }

  ngAfterViewInit() {
    document.documentElement.style.setProperty('--primary-color', this.settings.primary);
    document.documentElement.style.setProperty('--warn-color', this.settings.warn);
    document.documentElement.style.setProperty('--accent-color', this.settings.accent);
  }


}
