import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { Menu } from '../menu/menu.model';
import { MenuService } from '../menu/menu.service';
import { AuthService } from './../../../pages/core/auth.service';
import { User } from './../../../pages/core/user';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})
export class SidenavComponent implements OnInit {
  public psConfig: PerfectScrollbarConfigInterface = {
    wheelPropagation: true
  };
  public menuItems: Array<Menu>;
  public settings: Settings;
  public connectedUser: User;
  user: User;


  public userImage = "assets/img/users/user.jpg";
  constructor(public appSettings: AppSettings, public menuService: MenuService, private authService: AuthService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {

    this.menuItems = this.menuService.getVerticalMenuItems();

    this.connectedUser = this.authService.getUser();
    this.user = this.authService.getTheUserConnected();
  }

  ngDoCheck() {
    if (this.settings.fixedSidenav) {
      if (this.psConfig.wheelPropagation == true) {
        this.psConfig.wheelPropagation = false;
      }
    }
    else {
      if (this.psConfig.wheelPropagation == false) {
        this.psConfig.wheelPropagation = true;
      }
    }
  }

  public closeSubMenus() {
    let menu = document.getElementById("vertical-menu");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

}
