import { Component, OnInit, ViewChild, HostListener, ViewChildren, QueryList } from '@angular/core';
// import { Router, NavigationEnd } from '@angular/router';
import { PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { rotate } from '../theme/utils/app-animation';
import { MenuService } from '../theme/components/menu/menu.service';
import { NavigationEnd, Router } from '@angular/router';
import { User } from './core/user';
import { AuthService } from './core/auth.service';
import { environment } from 'src/environments/environment';
import { Unite } from '../simlait/unite/unite.model';
import { UniteService } from '../simlait/unite/unite.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  animations: [rotate],
  providers: [MenuService]
})
export class PagesComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;
  @ViewChild('backToTop') backToTop: any;
  @ViewChildren(PerfectScrollbarDirective) pss: QueryList<PerfectScrollbarDirective>;
  public optionsPsConfig: PerfectScrollbarConfigInterface = {};
  public settings: Settings;
  public showSidenav: boolean = false;
  public showInfoContent: boolean = false;
  public toggleSearchBar: boolean = false;
  private defaultMenu: string; //declared for return default menu when window resized 
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption: string;

  public user: User;
  datenow = new Date();

  backendUrl: string;
  constructor(public appSettings: AppSettings,
    private authService: AuthService,
    public router: Router,
    private menuService: MenuService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {

    this.backendUrl = environment.apiUrl;

    this.user = this.authService.getTheUserConnected();
    console.log(this.user)
    this.optionsPsConfig.wheelPropagation = false;
    if (window.innerWidth <= 960) {
      this.settings.menu = 'vertical';
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
    this.defaultMenu = this.settings.menu;
  }

  ngAfterViewInit() {
    setTimeout(() => { this.settings.loadingSpinner = false }, 300);
    this.backToTop.nativeElement.style.display = 'none';
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.scrollToTop();
      }
      if (window.innerWidth <= 960) {
        this.sidenav.close();
      }
    });
    if (this.settings.menu == "vertical")
      this.menuService.expandActiveSubMenu(this.menuService.getVerticalMenuItems());
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.defaultMenu = this.menuOption;
    if (this.menuOption == 'horizontal') {
      this.settings.fixedSidenav = false;
    }
    this.router.navigate(['/']);
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
  }

  public changeTheme(theme) {
    this.settings.theme = theme;

    if (this.settings.theme == 'yellow') {
      this.settings.warn = '#E51225'; this.settings.primary = '#FCD1A0'; this.settings.accent = '#008D36';
    } else if (this.settings.theme == 'indigo-light') {
      this.settings.warn = '#004d40'; this.settings.primary = '#1a237e'; this.settings.accent = '#00b8d4';
    } else if (this.settings.theme == 'red-light') {
      this.settings.warn = '#4a148c'; this.settings.primary = '#f8bbd0'; this.settings.accent = '#6a1b9a';
    } else if (this.settings.theme == 'gray-light') {
      this.settings.warn = '#f06292'; this.settings.primary = '#616161'; this.settings.accent = '#b71c1c';
    } else if (this.settings.theme == 'blue-dark') {
      this.settings.warn = '#1976d2'; this.settings.primary = '#304ffe'; this.settings.accent = '#4a148c';
    } else if (this.settings.theme == 'green-dark') {
      this.settings.warn = '#aeea00'; this.settings.primary = '#f8bbd0'; this.settings.accent = '#b2ff59';
    } else if (this.settings.theme == 'pink-dark') {
      this.settings.warn = '#0097a7'; this.settings.primary = '#f06292'; this.settings.accent = '#ba68c8';
    } else if (this.settings.theme == 'gray-dark') {
      this.settings.warn = '#fccc98'; this.settings.primary = '#546e7a'; this.settings.accent = '#fb8c00';
    }

    document.documentElement.style.setProperty('--primary-color', this.settings.primary);
    document.documentElement.style.setProperty('--warn-color', this.settings.warn);
    document.documentElement.style.setProperty('--accent-color', this.settings.accent);
  }

  public closeInfoContent(showInfoContent) {
    this.showInfoContent = !showInfoContent;
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    if (window.innerWidth <= 960) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = 'vertical'
    }
    else {
      (this.defaultMenu == 'horizontal') ? this.settings.menu = 'horizontal' : this.settings.menu = 'vertical'
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  public onPsScrollY(event) {
    (event.target.scrollTop > 300) ? this.backToTop.nativeElement.style.display = 'flex' : this.backToTop.nativeElement.style.display = 'none';
  }

  public scrollToTop() {
    this.pss.forEach(ps => {
      if (ps.elementRef.nativeElement.id == 'main') {
        ps.scrollToTop(0, 250);
      }
    });
  }

  public closeSubMenus() {
    if (this.settings.menu == "vertical") {
      this.menuService.closeAllSubMenus();
    }
  }
}