export const environment = {
  production: false,

  // apiUrl: '/api/public/index.php',
  // apiAuth: '/api/public/index.php',
  // apiUriBase: '/api/public/index.php',

  apiUrl: '/api',
  apiAuth: '/api',
  apiUriBase: '',
};  
