import { environment } from '../../../environments/environment';

export class BackendServerConf {
  //public static baseUrlBackend = "http://127.0.0.1:8000";
  public static baseUrlBackend = "https://api.emargis.com";
  public static baseUrlBackendPrincipal = "https://apisimlait.mascosolutions.com/api";
  //public static webUrl = "http://127.0.0.1:4200";
  public static webUrl = "https://emargis.com";
  //public static baseUrlBackend = `${environment.apiUrl}`;
  // public static baseUrlBackend = "http://192.168.1.12:8000";
  // public static serverUeadUploadDirFileUrl = "https://bdsp.massolutions.pro/upload-dir/";
  // public static serverUeadUploadDirFileUrl = "api/upload/nouveau/";
}
