import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalVariableService {


    formatPhoneNumber(phone: string) {
        let numero = phone.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4 ");
        return numero;
    }
}
