import { HistoryModule } from './simlait/history/history.module';

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './pages/blank/blank.component';
import { AuthGuardService } from './pages/core/auth-guard.service';
import { ErrorComponent } from './pages/errors/error/error.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';


export const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuardService],
    children: [

      { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
      { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), data: { breadcrumb: 'Users' } },
      { path: 'meet', loadChildren: () => import('./paens/meet/meet.module').then(m => m.MeetModule), data: { breadcrumb: 'Meet' } },
      { path: 'registre', loadChildren: () => import('./paens/registre/registre.module').then(m => m.RegistreModule), data: { breadcrumb: 'Registre' } },
      { path: 'agenda', loadChildren: () => import('./paens/agenda/agenda.module').then(m => m.AgendaModule), data: { breadcrumb: 'Agenda' } },
      { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' } },
      { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
      { path: 'form-controls', loadChildren: () => import('./pages/form-controls/form-controls.module').then(m => m.FormControlsModule), data: { breadcrumb: 'Form Controls' } },

      { path: 'formulaire', loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule), data: { breadcrumb: 'Formulaires' } },
      { path: 'dynamic-menu', loadChildren: () => import('./pages/dynamic-menu/dynamic-menu.module').then(m => m.DynamicMenuModule), data: { breadcrumb: 'Nouveau formulaire' } },

      //  
      // { path: 'maps', loadChildren: () => import('./pages/maps/maps.module').then(m => m.MapsModule), data: { breadcrumb: 'Maps' } },
      { path: 'charts', loadChildren: () => import('./pages/charts/charts.module').then(m => m.ChartsModule), data: { breadcrumb: 'Charts' } },
      //routes pour le simlait
      { path: 'user-mobile', loadChildren: () => import('./simlait/user-mobile/user-mobile.module').then(m => m.UserMobileModule), data: { breadcrumb: 'users' } },
      { path: 'conditionnement-mobile', loadChildren: () => import('./simlait/conditionnement/conditionnement.module').then(m => m.ConditionnementModule), data: { breadcrumb: 'conditionnement' } },
      { path: 'produit-mobile', loadChildren: () => import('./simlait/produit/produit.module').then(m => m.ProduitModule), data: { breadcrumb: 'produit' } },
      { path: 'emballage-mobile', loadChildren: () => import('./simlait/emballage/emballage.module').then(m => m.EmballageModule), data: { breadcrumb: 'emballage' } },
      { path: 'region-mobile', loadChildren: () => import('./simlait/region/region.module').then(m => m.RegionModule), data: { breadcrumb: 'region' } },
      { path: 'zone-mobile', loadChildren: () => import('./simlait/zone/zone.module').then(m => m.ZoneModule), data: { breadcrumb: 'zone' } },
      // { path: 'laiterie-mobile', loadChildren: () => import('./simlait/laiterie/laiterie.module').then(m => m.LaiterieModule), data: { breadcrumb: 'laiterie' } },
      { path: 'unite-mobile', loadChildren: () => import('./simlait/unite/unite.module').then(m => m.UniteModule), data: { breadcrumb: 'Unité' } },
      { path: 'departement-mobile', loadChildren: () => import('./simlait/departement/departement.module').then(m => m.DepartementModule), data: { breadcrumb: 'departement' } },
      { path: 'collecte-mobile', loadChildren: () => import('./simlait/collecte/collecte.module').then(m => m.CollecteModule), data: { breadcrumb: 'collecte' } },

      { path: 'historique-requette', loadChildren: () => import('./simlait/logger/logger.module').then(m => m.LoggerModule), data: { breadcrumb: 'logger' } },
      { path: 'statistique', loadChildren: () => import('./simlait/statistique/stat.module').then(m => m.StatModule), data: { breadcrumb: 'stat' } },
      { path: 'production', loadChildren: () => import('./simlait/mer/mer.module').then(m => m.MerModule), data: { breadcrumb: 'mer' } },
      // { path: 'transformateur', loadChildren: () => import('./simlait/transformateur/transformateur.module').then(m => m.TransformateurModule), data: { breadcrumb: 'transformateur' } },

      { path: 'mise-en-relation', loadChildren: () => import('./simlait/mise-en-relation/mise-en-relation.module').then(m => m.MiseEnRelationModule), data: { breadcrumb: 'mise-en-relation' } },
      { path: 'commercants', loadChildren: () => import('./simlait/vente/vente.module').then(m => m.VenteModule), data: { breadcrumb: 'vente' } },
      { path: 'eleveur', loadChildren: () => import('./simlait/eleveur/eleveur.module').then(m => m.EleveurModule), data: { breadcrumb: 'Élèveur' } },
      { path: 'producteurs', loadChildren: () => import('./simlait/production/production.module').then(m => m.ProductionModule), data: { breadcrumb: 'producteurs' } },
      { path: 'transformateurs', loadChildren: () => import('./simlait/transformateur/tramsformateur.module').then(m => m.TransformateurModule), data: { breadcrumb: 'transformateurs' } },
      { path: 'profil-produits', loadChildren: () => import('./simlait/profilProduits/profil.module').then(m => m.ProfilModule), data: { breadcrumb: 'profil-produits' } },
      { path: 'status', loadChildren: () => import('./simlait/status/status.module').then(m => m.StatusModule), data: { breadcrumb: 'status' } },
      { path: 'agregation', loadChildren: () => import('./simlait/agregation/agregation.module').then(m => m.AgregationModule), data: { breadcrumb: 'agregation' } },
      { path: 'notifi', loadChildren: () => import('./simlait/notification-mobile/notification-mobile.module').then(m => m.NotificationMobileModule), data: { breadcrumb: 'notification-mobile' } },
      { path: 'parametrage-mobile', loadChildren: () => import('./simlait/parametrage-mobile/parametre.module').then(m => m.ParametrageMobileModule), data: { breadcrumb: 'parametrage-mobile' } },
      { path: 'dynamic_table', loadChildren: () => import('./simlait/dynamicTable/dynamicTbale.module').then(m => m.DynamicTableModule), data: { breadcrumb: 'données-table-dynamic' } },
      { path: 'donnees-brute', loadChildren: () => import('./simlait/bruite/bruite.module').then(m => m.BruiteModule), data: { breadcrumb: 'données brutes' } },
      { path: 'config', loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationModule), data: { breadcrumb: 'config back' } },
      { path: 'opérations', loadChildren: () => import('./simlait/operation/operation.module').then(m => m.OperationModule), data: { breadcrumb: 'opération' } },
      { path: 'historique', loadChildren: () => import('./simlait/history/history.module').then(m => m.HistoryModule), data: { breadcrumb: 'historique Requettes' } },
      { path: 'saisons', loadChildren: () => import('./simlait/saison/saison.module').then(m => m.SaisonModule), data: { breadcrumb: 'Saisons' } },
      { path: 'roles', loadChildren: () => import('./simlait/roles/role.module').then(m => m.RoleModule), data: { breadcrumb: 'Rôles' } },


    ]
  },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  { path: 'reunion/:id', loadChildren: () => import('./paens/public/emarge-public.module').then(m => m.EmargePublicModule) },
  { path: 'privacy', loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'reset-password', loadChildren: () => import('./pages/resetPassword/reset-password.module').then(m => m.ResetPasswordModle) },
  { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
  { path: '**', component: NotFoundComponent },


];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      // relativeLinkResolution: 'legacy',
      // useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
