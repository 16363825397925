import { GlobalVariableService } from './../../utils/global-variable.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from './../../../pages/core/auth.service';
import { Router } from '@angular/router';
import { User } from 'src/app/pages/core/user';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = "assets/img/users/user.jpg";

  user: User;
  constructor(public authService: AuthService, private router: Router, public globalVar: GlobalVariableService ) { }

  ngOnInit() {
    this.user = this.authService.getTheUserConnected();
  }

  logout() {
    this.authService.logout();
  }

}
