import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendServerConf } from 'src/app/pages/core/backend-server-conf';
import { Unite } from './unite.model';
import { UnitesUser } from './UnitesUser.model';

@Injectable()

export class UniteService {

  readonly url: string = `${BackendServerConf.baseUrlBackend}/api/unites`;
  readonly urlUser: string = `${BackendServerConf.baseUrlBackend}/api/unites/userMobile`;
  readonly urlLU: string = `${BackendServerConf.baseUrlBackend}/api/unites_users`;
  readonly urlCreateUniteUser: string = `${BackendServerConf.baseUrlBackend}/api/unites_users/create`;
  readonly urlUnitesUsers: string = `${BackendServerConf.baseUrlBackend}/api/unites_users`;
  readonly urlUnitesUsersAssocie: string = `${BackendServerConf.baseUrlBackend}/api/unites/isAssociated`;

  readonly urlUnitesSames: string = `${BackendServerConf.baseUrlBackend}/api/unites/sames`;
  readonly urlUnitesAssociateSames: string = `${BackendServerConf.baseUrlBackend}/api/unites/associateSames`;

  constructor(public http: HttpClient) { }

  findAll(): Observable<Unite[]> {
    return this.http.get<Unite[]>(this.url).map(data => data);
  }

  findListSimple(): Observable<Unite[]> {
    return this.http.get<Unite[]>(`${BackendServerConf.baseUrlBackend}/api/unitesListe`).map(data => data);
  }

  findAllSimple(itemsPerPage, page): Observable<Unite[]> {
    return this.http.get<Unite[]>(`${BackendServerConf.baseUrlBackend}/api/unitesSimple/${itemsPerPage}/${page}`).map(data => data);
  }

  findAllSimpleDemande(): Observable<Unite[]> {
    return this.http.get<Unite[]>(`${BackendServerConf.baseUrlBackend}/api/uniteSimpleDemande`).map(data => data);
  }


  findAllSimples(): Observable<Unite[]> {
    return this.http.get<Unite[]>(`${BackendServerConf.baseUrlBackend}/api/unitesSimple`).map(data => data);
  }

  findCollecteQuantite(id) {
    return this.http.get<any>(`${BackendServerConf.baseUrlBackend}/api/unitesQuantiteTotal/` + id).map(data => data);
  }

  updateUser(form: any) {
    return this.http.post(`${this.urlUser}`, form);
  }

  findLaiterieuSER(body): Observable<any> {
    return this.http.post(this.urlLU + "/search", body);
  }

  findOneLaiterie(id: string) {
    return this.http.get(this.url + "/" + id);
  }

  createLaiterie(form): Observable<any> {
    return this.http.post(this.url, form);
  }

  delete(id: string) {
    return this.http.delete(`${this.url}/${id}`);
  }

  getSizCollecte(id): Observable<any> {
    return this.http.get<number>(`${this.url}/collectes/${id}`);
  }
  findOne(id: string) {
    return this.http.get<Unite>(`${this.url}/${id}`);
  }

  update(form: any) {
    return this.http.put(`${this.url}/${form.id}`, form);
  }

  createUnitesUser(body): Observable<any> {
    return this.http.post(this.urlCreateUniteUser, body);
  }

  findAllLaiterieUser(): Observable<UnitesUser[]> {
    return this.http.get<UnitesUser[]>(this.urlUnitesUsers).map(data => data);
  }

  findUnitesUserAssocited(body): Observable<any> {
    return this.http.post<UnitesUser[]>(this.urlUnitesUsersAssocie, body).map(data => data);
  }

  findUnitesUser(id): Observable<Unite[]> {
    return this.http.get<Unite[]>(this.url + "/user/" + id).map(data => data);
  }

  findUnitesSames(body): Observable<Unite[]> {
    return this.http.post<any>(this.urlUnitesSames, body).map(data => data);
  }

  doUnitesUsersAssocie(body): Observable<Unite[]> {
    return this.http.post<any>(this.urlUnitesAssociateSames, body).map(data => data);
  }
}
